import { Box } from "@mui/material";

const priorityProvider = (priority: string | undefined) => {
    let backgroundColor: string;

    switch (priority) {
        case "0":
            backgroundColor = "#76e28d";
            break;
        case "1":
            backgroundColor = "#FFC800";
            break;
        case "2":
            backgroundColor = "#E70E02";
            break;
        default:
            backgroundColor = "#FFFFFF";
            break;
    }

    return (
        <Box
            sx={{
                backgroundColor,
                width: "25px",
                height: "25px",
                borderRadius: "50%",
                marginLeft: "auto",
                marginRight: "auto",
            }}
        ></Box>
    );
};

export default priorityProvider;
