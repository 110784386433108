import {
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Mission, MissionToUpdate } from "../../types/Mission";
import { Tag } from "../../types/Tag";
import { updateMission as apiUpdateMission } from "api/missions";
import Editor from "react-simple-wysiwyg";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export type UpdateMissionProps = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
    projectId: number;
    tags: Tag[];
    missionToUpdate: Mission;
};

export const UpdateMission: React.FC<UpdateMissionProps> = ({ setOpen, refetch, projectId, tags, missionToUpdate }) => {
    const [mission, setMission] = useState<MissionToUpdate>({
        ...missionToUpdate,
        tags: missionToUpdate.tags.map((t) => t.id),
    });

    const [showDates, setShowDates] = useState<boolean>(false);

    const updateMission = (key: string, val: string) => {
        setMission((prevMission) => ({
            ...prevMission,
            [key]: val,
        }));
    };

    const handleChange = (event: SelectChangeEvent<any>) => {
        const {
            target: { value },
        } = event;
        setMission((prevMission) => ({
            ...prevMission,
            tags: typeof value === "string" ? value.split(",") : value,
        }));
    };

    const handleChangePriority = (event: SelectChangeEvent<any>) => {
        const {
            target: { value },
        } = event;
        setMission((prevMission) => ({
            ...prevMission,
            priority: value,
        }));
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowDates(event.target.checked);
    };

    const formHasError = (mission: MissionToUpdate) => Object.values(mission).reduce((acc, val) => acc || !val, false);

    const submit = () => {
        if (formHasError(mission)) {
            return;
        }
        apiUpdateMission(mission.id, {
            ...mission,
            project_id: projectId,
        }).then((data) => {
            setOpen(false);
            refetch();
        });
    };

    return (
        <Container>
            <FormControl fullWidth sx={{ m: 1, mb: 2 }}>
                <TextField
                    label="Titre de la mission"
                    variant="standard"
                    value={mission.name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        updateMission("name", event.target.value);
                    }}
                />
            </FormControl>

            <FormControl fullWidth sx={{ m: 1, mb: 2 }}>
                <FormLabel>Description</FormLabel>

                <Editor
                    value={mission.description}
                    onChange={(event) => {
                        updateMission("description", event.target.value);
                    }}
                />
            </FormControl>

            <FormControl sx={{ m: 1, mb: 2, width: "100%" }}>
                <InputLabel id="demo-multiple-name-label">Tags</InputLabel>
                <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={mission.tags}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tags" />}
                    MenuProps={MenuProps}
                >
                    {tags.map((tag: Tag) => (
                        <MenuItem key={tag.label} value={tag.id}>
                            {tag.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, mb: 2, width: "100%" }}>
                <InputLabel id="demo-name-label">Priorité</InputLabel>
                <Select
                    labelId="demo-name-label"
                    id="demo-name"
                    required
                    value={mission.priority}
                    onChange={handleChangePriority}
                    input={<OutlinedInput label="Client de" />}
                    MenuProps={MenuProps}
                >
                    <MenuItem key="Priorité basse" value="0">
                        Priorité basse
                    </MenuItem>
                    <MenuItem key="Priorité moyenne" value="1">
                        Priorité moyenne
                    </MenuItem>
                    <MenuItem key="Priorité haute" value="2">
                        Priorité haute
                    </MenuItem>
                </Select>
            </FormControl>

            <FormControlLabel
                sx={{ mb: 2 }}
                control={<Checkbox checked={showDates} onChange={handleCheckboxChange} />}
                label="Saisir une date de début et/ou de fin"
            />

            {showDates && (
                <>
                    <FormControl sx={{ m: 1, mb: 4, width: "15ch" }}>
                        <TextField
                            label="Date de début"
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            variant="standard"
                            value={mission.start_at}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                updateMission("start_at", event.target.value);
                            }}
                        />
                    </FormControl>

                    <FormControl sx={{ m: 1, mb: 4, width: "15ch" }}>
                        <TextField
                            label="Date de fin"
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            variant="standard"
                            value={mission.end_at}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                updateMission("end_at", event.target.value);
                            }}
                        />
                    </FormControl>
                </>
            )}

            <Container sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button variant="contained" onClick={submit}>
                    Modifier la mission
                </Button>
            </Container>
        </Container>
    );
};
