import {
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Project, ProjectToCreate } from "../../types/Project";
import { Client } from "../../types/Client";
import { updateProject as updateProjectApi } from "api/projects";
import { getClients } from "api/clients";

export type UpdateProjectProps = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
    projectToUpdate: Project;
};

export const UpdateProject: React.FC<UpdateProjectProps> = ({ setOpen, refetch, projectToUpdate }) => {
    const [project, setProject] = useState<Project>(projectToUpdate);

    const [showDates, setShowDates] = useState<boolean>(false);
    const [clients, setClients] = useState<Client[]>();

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowDates(event.target.checked);
    };

    const updateProject = (key: string, val: any) => {
        setProject((prevProject) => ({
            ...prevProject,
            [key]: val,
        }));
    };

    const formHasError = (project: Partial<ProjectToCreate>) => Object.values(project).reduce((acc, val) => acc || !val, false);

    const submit = () => {
        if (formHasError(project)) {
            return;
        }

        updateProjectApi(project).then((data) => {
            setOpen(false);
            refetch();
        });
    };

    const fetchClients = () => {
        getClients().then((data) => {
            setClients(data.data);
        });
    };

    useEffect(() => {
        fetchClients();
    }, []);

    return (
        <Container>
            <FormControl fullWidth sx={{ m: 1, mb: 2 }}>
                <TextField
                    label="Titre du projet"
                    variant="standard"
                    value={project.name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        updateProject("name", event.target.value);
                    }}
                />
            </FormControl>

            {/* <FormControl fullWidth sx={{ m: 1, mb: 2 }}>
                <InputLabel id="demo-simple-select-label">Client</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={`${project.client_id ?? ""}`}
                    label="Client"
                    onChange={handleClient}
                >
                    {clients && clients.map((client: Client) => (
                        <MenuItem value={client.id}>
                            {client.firstname} {client.lastname} ({client.company})
                        </MenuItem>
                    ))}
                </Select>
            </FormControl> */}

            <FormControl fullWidth sx={{ m: 1, mb: 2 }}>
                <TextField
                    label="Prix du projet"
                    variant="standard"
                    type="number"
                    value={project.price ?? undefined}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        updateProject("price", event.target.value);
                    }}
                />
            </FormControl>

            <FormControlLabel
                sx={{ mb: 2 }}
                control={<Checkbox checked={showDates} onChange={handleCheckboxChange} />}
                label="Saisir une date de début et/ou de fin"
            />

            {showDates && (
                <>
                    <FormControl sx={{ m: 1, mb: 2, width: "15ch" }}>
                        <TextField
                            label="Date de début"
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            variant="standard"
                            value={project.start_at}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                updateProject("start_at", event.target.value);
                            }}
                        />
                    </FormControl>

                    <FormControl sx={{ m: 1, mb: 2, width: "15ch" }}>
                        <TextField
                            label="Date de fin"
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            variant="standard"
                            value={project.end_at}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                updateProject("end_at", event.target.value);
                            }}
                        />
                    </FormControl>
                </>
            )}

            <Container sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button variant="contained" onClick={submit}>
                    Modifier le projet
                </Button>
            </Container>
        </Container>
    );
};

{
    /* 
    {Object.keys(project).map((key: string) => (
        <TextField key={key} label={key} variant="standard" value={project[key as keyof Project]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updateProject(key, event.target.value);
            }}
        />
    ))} 
*/
}
