import {
    Button,
    Container,
    FormControl,
    FormLabel,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Client } from "../../types/Client";
import { addClient } from "api/clients";
import { Tag } from "types/Tag";
import Editor from "react-simple-wysiwyg";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export type CreateClientProps = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
    tags: Tag[];
};

export const CreateClient: React.FC<CreateClientProps> = ({ setOpen, refetch, tags }) => {
    const [client, setClient] = useState<Partial<Client>>({
        firstname: "",
        lastname: "",
        company: "",
        email: "",
        phone: "",
        address: "",
        zipcode: "",
        town: "",
        siret: "",
        priority: "",
        brief: "",
        tags: [],
    });

    const updateClient = (key: string, val: any) => {
        setClient((prevClient) => ({
            ...prevClient,
            [key]: val,
        }));
    };

    const handleChangeAgency = (event: SelectChangeEvent<any>) => {
        const {
            target: { value },
        } = event;
        setClient((prevClient) => ({
            ...prevClient,
            agency: value,
        }));
    };

    const handleChangePriority = (event: SelectChangeEvent<any>) => {
        const {
            target: { value },
        } = event;
        setClient((prevClient) => ({
            ...prevClient,
            priority: value,
        }));
    };

    const handleChangeTags = (event: SelectChangeEvent<any>) => {
        const {
            target: { value },
        } = event;
        setClient((prevClient) => ({
            ...prevClient,
            tags: typeof value === "string" ? value.split(",") : value,
        }));
    };

    const formHasError = (client: Partial<Client>) =>
        Object.entries(client).reduce((acc, [key, val]) => (key === "siret" ? acc || false : acc || !val || !`${val}`.length), false);

    const submit = (e: any) => {
        e.preventDefault();
        if (formHasError(client)) {
            return;
        }

        addClient(client).then((data) => {
            setOpen(false);
            refetch();
        });
    };

    return (
        <Container>
            <form>
                <FormControl sx={{ m: 1, mb: 2, width: "47.5%" }}>
                    <TextField
                        label="Prénom"
                        required
                        variant="standard"
                        value={client.firstname}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateClient("firstname", event.target.value);
                        }}
                    />
                </FormControl>

                <FormControl sx={{ m: 1, mb: 2, width: "47.5%" }}>
                    <TextField
                        label="Nom de famille"
                        required
                        variant="standard"
                        value={client.lastname}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateClient("lastname", event.target.value);
                        }}
                    />
                </FormControl>

                <FormControl fullWidth sx={{ m: 1, mb: 2 }}>
                    <TextField
                        label="Adresse e-mail"
                        required
                        type="email"
                        variant="standard"
                        value={client.email}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateClient("email", event.target.value);
                        }}
                    />
                </FormControl>

                <FormControl sx={{ m: 1, mb: 2, width: "47.5%" }}>
                    <TextField
                        label="Entreprise"
                        required
                        variant="standard"
                        value={client.company}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateClient("company", event.target.value);
                        }}
                    />
                </FormControl>

                <FormControl sx={{ m: 1, mb: 2, width: "47.5%" }}>
                    <TextField
                        label="N° de téléphone"
                        required
                        type="phone"
                        variant="standard"
                        value={client.phone}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateClient("phone", event.target.value);
                        }}
                    />
                </FormControl>

                <FormControl sx={{ m: 1, mb: 2, width: "47.5%" }}>
                    <TextField
                        label="Zipcode"
                        required
                        variant="standard"
                        value={client.zipcode}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateClient("zipcode", event.target.value);
                        }}
                    />
                </FormControl>

                <FormControl sx={{ m: 1, mb: 2, width: "47.5%" }}>
                    <TextField
                        label="Ville"
                        required
                        variant="standard"
                        value={client.town}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateClient("town", event.target.value);
                        }}
                    />
                </FormControl>

                <FormControl fullWidth sx={{ m: 1, mb: 2 }}>
                    <TextField
                        label="Adresse"
                        required
                        variant="standard"
                        value={client.address}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateClient("address", event.target.value);
                        }}
                    />
                </FormControl>

                <FormControl fullWidth sx={{ m: 1, mb: 2 }}>
                    <TextField
                        label="SIRET"
                        variant="standard"
                        value={client.siret}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateClient("siret", event.target.value);
                        }}
                    />
                </FormControl>

                <FormControl fullWidth sx={{ m: 1, mb: 2 }}>
                    <FormLabel>Brief</FormLabel>

                    <Editor
                        value={client.brief}
                        onChange={(event) => {
                            updateClient("brief", event.target.value);
                        }}
                    />
                </FormControl>

                <FormControl sx={{ m: 1, mb: 2, width: "100%" }}>
                    <InputLabel id="demo-name-label">Client de</InputLabel>
                    <Select
                        labelId="demo-name-label"
                        id="demo-name"
                        required
                        value={client.agency}
                        onChange={handleChangeAgency}
                        input={<OutlinedInput label="Client de" />}
                        MenuProps={MenuProps}
                    >
                        <MenuItem key="Getup Agency" value="Getup Agency">
                            Getup Agency
                        </MenuItem>
                        <MenuItem key="Héhocom" value="Héhocom">
                            Héhocom
                        </MenuItem>
                    </Select>
                </FormControl>

                <FormControl sx={{ m: 1, mb: 2, width: "100%" }}>
                    <InputLabel id="demo-multiple-name-label">Tags</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={client.tags}
                        onChange={handleChangeTags}
                        input={<OutlinedInput label="Tags" />}
                        MenuProps={MenuProps}
                    >
                        {tags.map((tag: Tag) => (
                            <MenuItem key={tag.label} value={tag.id}>
                                {tag.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ m: 1, mb: 2, width: "100%" }}>
                    <InputLabel id="demo-name-label">Priorité</InputLabel>
                    <Select
                        labelId="demo-name-label"
                        id="demo-name"
                        required
                        value={client.priority}
                        onChange={handleChangePriority}
                        input={<OutlinedInput label="Client de" />}
                        MenuProps={MenuProps}
                    >
                        <MenuItem key="Priorité basse" value="0">
                            Priorité basse
                        </MenuItem>
                        <MenuItem key="Priorité moyenne" value="1">
                            Priorité moyenne
                        </MenuItem>
                        <MenuItem key="Priorité haute" value="2">
                            Priorité haute
                        </MenuItem>
                    </Select>
                </FormControl>

                <Button type="submit" variant="contained" onClick={submit}>
                    Créer le client
                </Button>
            </form>
        </Container>
    );
};

{
    /* 
    {Object.keys(client).map((key: string) => (
        <TextField key={key} label={key} variant="standard" value={client[key as keyof Client]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updateClient(key, event.target.value);
            }}
        />
    ))} 
*/
}
